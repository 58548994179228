import { gql } from '@apollo/client';

const MUTATION_SENDMESSAGE = gql`
  mutation sendContactUsEmail($input: sendContactUsEmailInput!){
    sendContactUsEmail(input: $input) {
      id
      firstName
      lastName
      designation
      company
      contactNumber
      emailAddress
      message
    }
  }
`;

export default MUTATION_SENDMESSAGE;