import React, { useRef } from "react";
import { HeaderImg } from "../../assets";
import { Grid, Box, Typography } from "@mui/material";
import { Animated } from "react-animated-css";
import { useInView } from "framer-motion";
import AppParallax from "../../component/AppParallax";

const styles = {
  grid: {
    overflow: "hidden",
    objectFit: "contain",
  },
  img: {
    width: { xs: "100%", sm: "100%", md: "130%", lg: "130%", xl: "130%" },
    clipPath: { md: "polygon(0 0, 78% 0, 100% 100%, 0 100%)" },
    zIndex: -2,
    objectFit: "contain",
  },
  box: {
    zIndex: 100,
    m: { xs: 2, sm: 3 },
  },
  title: {
    color: "#23295f",
    fontWeight: "bold",
    fontSize: {
      xs: "3rem",
      sm: "4rem",
      md: "4rem",
      lg: "5.5rem",
      xl: "7rem",
    },
  },
  text: {
    fontSize: {
      xs: "1rem",
      sm: "1.5rem",
      md: "1.5rem",
      lg: "1.5rem",
      xl: "3rem",
    },
  },
  backBox: {
    display: { xs: "none", md: "flex" },
    zIndex: -100,
    p: 100,
    position: "absolute",
    top: -300,
    left: { md: -250, lg: -270, xl: -350 },
    background:
      "linear-gradient(90deg, rgba(255,255,255,0.5018601190476191) 20%, rgba(255,255,255,0) 100%)",
    clipPath: "polygon(0 0, 100% 0, 100% 100%, 30% 100%)",
  },
};

function Header() {
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });

  return (
    <Grid
      container
      columnSpacing={6}
      justifyContent="center"
      alignItems="center"
      className="header"
      component="div"
      position="relative"
      ref={ref}
      sx={styles.grid}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        overflow={{ xs: "hidden", md: "visible" }}
      >
        <Animated
          animationIn={"fadeInLeft"}
          animationInDuration={1500}
          animationOutDuration={1000}
          isVisible={inView}
        >
          <AppParallax
            element={
              <Box
                component="img"
                src={HeaderImg.header.src}
                alt={HeaderImg.header.alt}
                sx={styles.img}
              />
            }
            styles={{ overflow: "visible" }}
          />
        </Animated>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Box
          sx={styles.box}
          component={Animated}
          animationIn="fadeInRight"
          animationOut="fadeOutUp"
          animationInDuration={1500}
          animationOutDuration={1000}
          isVisible={inView}
        >
          <Box sx={styles.backBox} />
          <Typography
            variant="h1"
            color="initial"
            sx={styles.title}
            component="p"
          >
            Bringing the Best of the World to the Philippines
          </Typography>
          <Typography variant="p" color="initial" sx={styles.text}>
            Providing Efficient Distribution Services That Links Brands To
            Retailers And Consumers
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Header;
