import { useState, useCallback, useEffect } from 'react'

function useForm() {
  const [formData, setFormData] = useState({});
  const [action, setAction] = useState(null);

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name] : value,
    }));
    setAction('onChange');
  }, []);

  const handleFile = useCallback((file) => {
    setFormData(prevData => ({
      ...prevData,
      file,
    }));
    setAction('onFileChange');
  }, []);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    setFormData(prevData => ({
      ...prevData,
    }));
    setAction('onSubmit');
  }, []);

  useEffect(() => {
    if (action) {
      setAction(null);
    }
  }, [action])

  return {
    formData,
    action,
    handleSubmit,
    handleChange,
    handleFile,
  }
}

export default useForm
