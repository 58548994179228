import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { routes as AppRoute } from "./routes";
import Navbar from "./partials/Navbar";
import Footer from "./partials/Footer";
import CookiePopUp from "./partials/CookiePopUp";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
} from "@apollo/client";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { onError } from "@apollo/client/link/error";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0, // phones
      sm: 600, // tablets
      md: 960, // small laptops
      lg: 1280, // desktops
      xl: 1920, // large screens
    },
    ranges: {
      xs: "(max-width: 599.99px)", // phones (up to 599.99px)
      sm: "(min-width: 600px) and (max-width: 959.99px)", // tablets (600px - 959.99px)
      md: "(min-width: 960px) and (max-width: 1279.99px)", // small laptops (960px - 1279.99px)
      lg: "(min-width: 1280px) and (max-width: 1919.99px)", // desktops (1280px - 1919.99px)
      xl: "(min-width: 1920px)", // large screens (1920px and above)
    },
  },
  typography: {
    fontFamily: `"Sora", sans-serif !important`,
    fontStyle: "normal !important",
    fontOpticalSizing: "auto",
    webkitFontSmoothing: "antialiased",
    mozOsxFontSmoothing: "grayscale",
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
});

const errorLink = onError(({ graphqlErrors }) => {
  if (graphqlErrors) {
    graphqlErrors.map((message, location, path) =>
      console.log(`GraphQL_ERROR: ${message} : ${location} : ${path}`)
    );
  }
});

/*
credentials: 'same-origin' - if your backend server is the same domain
credentials: 'include' - if your backend is a different domain.
*/
const link = from([
  errorLink,
  createUploadLink({
    uri: process.env.REACT_APP_LOCAL_GRAPHQL_URI,
    credentials: "same-origin",
    headers: {
      "keep-alive": true,
    }
  }),
  // new HttpLink({
  //   uri: process.env.REACT_APP_LOCAL_GRAPHQL_URI,
  //   credentials: "same-origin",
  // }),
  // new HttpLink({ uri: 'http://ec2-3-27-249-223.ap-southeast-2.compute.amazonaws.com/graphql', credentials: "same-origin" }),
]);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <ParallaxProvider>
          <ThemeProvider theme={theme}>
            <Navbar />
            <AppRoute />
            <CookiePopUp />
            <Footer />
          </ThemeProvider>
        </ParallaxProvider>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>
);
