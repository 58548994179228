import React from 'react'

function NoPage() {
  return (
    <div>
      404
    </div>
  )
}

export default NoPage
