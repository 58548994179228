import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import useForm from "../hooks/useForm";
import FileInputForm from "../component/FileInputForm";
import Validate from "../validations/index";
import applySchema from "../validations/schema/validate-applyForm";
import contactSchema from "../validations/schema/validate-contactForm";
import { useMutation } from "@apollo/client";
import MUTATION_SENDAPPLICATION from "../graphql/mutation-setApplication";
import MUTATION_SENDMESSAGE from "../graphql/mutation-setMessage";
import { isEmpty } from "lodash";

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    backgroundColor: "#23295b",
    fontSize: {
      xs: ".8rem",
      sm: "1rem",
      md: "1rem",
      lg: "1rem",
      xl: "1.3rem",
    },
    textTransform: "none",
  },
};

const limitRegex = /429/gi;
const isLimit = (str) =>
  str.match(limitRegex)
    ? "Too many request from this IP, please try again in 15 minutes."
    : str;

const formAction = (view) => {
  if (["apply", "careers"].includes(view)) {
    return { schema: applySchema, mutation: MUTATION_SENDAPPLICATION };
  } else {
    return { schema: contactSchema, mutation: MUTATION_SENDMESSAGE };
  }
};

function AppForm(props) {
  const { view } = props;
  const ref = useRef();
  const { schema, mutation } = formAction(view);
  const [formErrors, setFormErrors] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dataMessage, setDataMessage] = useState({
    state: false,
    message: null,
  });
  const [dataLoading, setDataLoading] = useState(true);
  const [sendForm] = useMutation(mutation);
  const { formData, action, handleSubmit, handleChange, handleFile } =
    useForm();

  useEffect(() => {
    if (action && action === "onSubmit") {
      async function formSubmit() {
        const { validData, valid, errors } = new Validate(formData, schema);
        if (valid) {
          setDialogOpen(true);
          setFormErrors(null);
          try {
            setDataLoading(true);
            const {
              data = null,
              loading,
              error,
            } = await sendForm({
              variables: {
                input: {
                  ...validData,
                },
              },
            });
            if (!loading) {
              setDataLoading(false);
            }
            if (!isEmpty(data) && !error) {
              ref?.current?.reset();
              return setDataMessage({
                state: true,
                message: "Your email has been sent successfully!",
              });
            }
            if (error) {
              return setDataMessage({ state: false, message: error });
            }
          } catch (error) {
            setDataLoading(false);
            return setDataMessage({ state: false, message: error?.message });
          }
          return setDataLoading(false);
        }
        return setFormErrors(errors);
      }
      formSubmit();
    }
  }, [action, view, formData, schema, sendForm]);

  return (
    <Box
      component="form"
      ref={ref}
      onSubmit={(event) => handleSubmit(event)}
      sx={styles.container}
    >
      <Grid
        container
        spacing={2}
        item
        xs={11}
        md={view === "careers" ? 10 : 6}
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name="firstName"
            label="First name (required)"
            onChange={handleChange}
            error={formErrors?.firstName ? true : null}
            helperText={formErrors?.firstName || null}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name="lastName"
            label="Last name (required)"
            onChange={handleChange}
            error={formErrors?.lastName ? true : null}
            helperText={formErrors?.lastName || null}
          />
        </Grid>
        {view === "contact" ? (
          <>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="designation"
                label="Designation (required)"
                onChange={handleChange}
                error={formErrors?.designation ? true : null}
                helperText={formErrors?.designation || null}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="company"
                label="Company (required)"
                onChange={handleChange}
                error={formErrors?.company ? true : null}
                helperText={formErrors?.company || null}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="contactNumber"
                label="Contact Number (required)"
                onChange={handleChange}
                error={formErrors?.contactNumber ? true : null}
                helperText={formErrors?.contactNumber || null}
              />
            </Grid>
          </>
        ) : null}
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="emailAddress"
            label="Email (required)"
            onChange={handleChange}
            error={formErrors?.emailAddress ? true : null}
            helperText={formErrors?.emailAddress || null}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="message"
            label="Message"
            variant="outlined"
            multiline
            fullWidth
            rows={5}
            onChange={handleChange}
            error={formErrors?.message ? true : null}
            helperText={formErrors?.message || null}
          />
        </Grid>
        <Grid item xs={12}>
          {["apply","careers"].includes(view) ? (
            <FileInputForm
              onFileChange={handleFile}
              errors={formErrors?.file}
            />
          ) : null}
        </Grid>

        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={styles.button}
          >
            {["apply", "careers"].includes(view) ? "Submit" : "Send Message"}
          </Button>
        </Grid>
      </Grid>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dataLoading
            ? null
            : dataMessage?.state
            ? "Form Submitted!"
            : !dataMessage?.state
            ? "Form Error"
            : null}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dataLoading ? (
              <CircularProgress color="inherit" />
            ) : (
              isLimit(dataMessage?.message)
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {dataLoading ? null : (
            <Button variant="text" onClick={() => setDialogOpen(false)}>
              Ok
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
}

AppForm.propTypes = {
  view: PropTypes.string,
};

AppForm.defaultProps = {
  view: null,
};

export default AppForm;
