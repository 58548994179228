// IMPORT

// Icons
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEarthAsia,
  faUsers,
  faStore,
  faDesktop,
  faTruckMoving,
  faWarehouse,
  faCartArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { faLightbulb } from "@fortawesome/free-regular-svg-icons";
/* ---------------------------------------------------------------------------------------------------------------------- */

//EXPORT

// Main site logo
export const LOGO = {
  src: require("./logo.png"),
  alt: "BIRC Logo",
};

// Alt
export { faImage as ALT } from "@fortawesome/free-solid-svg-icons/faImage";

// Header images
export const HeaderImg = {
  header: {
    src: require("./misc/home-header.png"),
    alt: "header_bg_img",
  },
  products: {
    src: require("./misc/product-header-final.png"),
    alt: "product_bg_img",
  },
  services: {
    src: require("./misc/our-service-banner.png"),
    alt: "services_bg_img",
  },
};

// Marketing and Distribution
export const MDVideo = {
  src: require("./marketing-distribution/promo-video.mp4"),
  alt: "promo-video",
};

export const MDMain = {
  src: require("./marketing-distribution/we-build-brands-photo 2.png"),
  alt: "media_main_img",
};

export const MDAssets = [
  [
    {
      src: require("./marketing-distribution/images/img-1.1.jpg"),
      alt: "media1.1",
    },
    {
      src: require("./marketing-distribution/images/img-1.2.jpg"),
      alt: "media1.2",
    },
    {
      src: require("./marketing-distribution/images/img-1.3.jpg"),
      alt: "media1.3",
    },
    {
      src: require("./marketing-distribution/images/img-1.4.jpg"),
      alt: "media1.4",
    },
  ],
  [
    {
      src: require("./marketing-distribution/images/img-2.1.jpg"),
      alt: "media2.1",
    },
    {
      src: require("./marketing-distribution/images/img-2.2.jpg"),
      alt: "media2.2",
    },
    {
      src: require("./marketing-distribution/images/img-2.3.jpg"),
      alt: "media2.3",
    },
    {
      src: require("./marketing-distribution/images/img-2.4.png"),
      alt: "media2.4",
    },
  ],
  [
    {
      src: require("./marketing-distribution/images/img-3.1.png"),
      alt: "media3.1",
    },
    {
      src: require("./marketing-distribution/images/img-3.2.png"),
      alt: "media3.2",
    },
    {
      src: require("./marketing-distribution/images/img-3.3.png"),
      alt: "media3.3",
    },
    {
      src: require("./marketing-distribution/images/img-3.4.png"),
      alt: "media3.4",
    },
  ],
];

// Partners
export const BrandPartners = [
  { src: require("./brand-partners/mondelez-logo.png"), alt: "mondelez", style: { transform: "scale(1.3)" } },
  { src: require("./brand-partners/unilever-logo.png"), alt: "unilever", style: { transform: "scale(0.6)" } },
  { src: require("./brand-partners/torres-logo.png"), alt: "torres", style: { transform: "scale(0.6)" } },
  { src: require("./brand-partners/reckitt-logo.webp"), alt: "reckitt" },
  { src: require("./brand-partners/permex-logo.png"), alt: "permex" },
];

export const TradePartners = [
  {
    src: require("./trade-partners/Trade Channel Icons/all-day-supermarket.png"),
    alt: "all_day_supermarket",
  },
  {
    src: require("./trade-partners/Trade Channel Icons/landers-superstore.png"),
    alt: "landers_superstore",
  },
  {
    src: require("./trade-partners/Trade Channel Icons/landmark.png"),
    alt: "landmark",
  },
  {
    src: require("./trade-partners/Trade Channel Icons/mercury-drug.png"),
    alt: "mercurydrug",
  },
  {
    src: require("./trade-partners/Trade Channel Icons/pure-gold-duty-free.png"),
    alt: "puregold",
  },
  {
    src: require("./trade-partners/Trade Channel Icons/robinsons-easymart.png"),
    alt: "robinsons_easymart",
  },
  {
    src: require("./trade-partners/Trade Channel Icons/robinsons-supermarket.png"),
    alt: "robinsons_supermarket",
  },
  {
    src: require("./trade-partners/Trade Channel Icons/royal-duty-free.png"),
    alt: "royalduty",
  },
  {
    src: require("./trade-partners/Trade Channel Icons/savemore.png"),
    alt: "savemore",
  },
  {
    src: require("./trade-partners/Trade Channel Icons/shell-select.png"),
    alt: "shell",
  },
  {
    src: require("./trade-partners/Trade Channel Icons/shopwise.png"),
    alt: "shopwise",
  },
  {
    src: require("./trade-partners/Trade Channel Icons/sm-hypermarket.png"),
    alt: "sm_hm",
  },
  {
    src: require("./trade-partners/Trade Channel Icons/sm-supermarket.png"),
    alt: "sm_sm",
  },
  { src: require("./trade-partners/Trade Channel Icons/sr.png"), alt: "sr" },
  {
    src: require("./trade-partners/Trade Channel Icons/ther-marketplace.png"),
    alt: "ther",
  },
  {
    src: require("./trade-partners/Trade Channel Icons/treats.png"),
    alt: "treats",
  },
  {
    src: require("./trade-partners/Trade Channel Icons/waltermart.png"),
    alt: "waltermart",
  },
];

// Products
export const BrandProducts = {
  mondelez: [
    {
      brand: {
        src: require("./brand-partners/products/Mondelez/Chips Ahoy/chips-ahoy-logo.png"),
        alt: "chips_ahoy",
      },
      product: {
        src: require("./brand-partners/products/Mondelez/Chips Ahoy/chipsahoy-final.png"),
        alt: "p_chips_ahoy",
        style: { transform: "scale(1.4)" },
      },
    },
    {
      brand: {
        src: require("./brand-partners/products/Mondelez/Ritz/ritz-logo.png"),
        alt: "ritz",
      },
      product: {
        src: require("./brand-partners/products/Mondelez/Ritz/Ritz-final.png"),
        alt: "p_ritz",
        style: { transform: "scale(1.3)" },
      },
    },
    {
      brand: {
        src: require("./brand-partners/products/Mondelez/Jacobs/jacobs-logo.webp"),
        alt: "jacobs",
      },
      product: {
        src: require("./brand-partners/products/Mondelez/Jacobs/jacobs-final.png"),
        alt: "p_jacobs",
        style: { transform: "scale(1.3)" },
      },
    },
    {
      brand: {
        src: require("./brand-partners/products/Mondelez/Chips More/chipsmore-logo.webp"),
        alt: "chips_more",
      },
      product: {
        src: require("./brand-partners/products/Mondelez/Chips More/chipsmore-final.png"),
        alt: "p_chips_more",
        style: { transform: "scale(1.5)" },
      },
    },
    {
      brand: {
        src: require("./brand-partners/products/Mondelez/Cadbury/cadbury-logo.png"),
        alt: "cadbury",
      },
      product: {
        src: require("./brand-partners/products/Mondelez/Cadbury/cadbury-final.png"),
        alt: "p_cadbury",
        style: { transform: "scale(1.5)" },
      },
    },
    {
      brand: {
        src: require("./brand-partners/products/Mondelez/Teddy Graham/teddy-grahams-logo.png"),
        alt: "teddy_grahams",
      },
      product: {
        src: require("./brand-partners/products/Mondelez/Teddy Graham/teddy-final.png"),
        alt: "p_teddy_grahams",
        style: { transform: "scale(1.4)" },
      },
    },
    {
      brand: {
        src: require("./brand-partners/products/Mondelez/Milka/milka-logo.png"),
        alt: "milka",
      },
      product: {
        src: require("./brand-partners/products/Mondelez/Milka/milka-final.png"),
        alt: "p_milka",
        style: { transform: "scale(1.5)" },
      },
    },
  ],
  unilever: [
    {
      brand: {
        src: require("./brand-partners/products/Unilever/Dove/dove-logo.png"),
        alt: "dove",
      },
      product: {
        src: require("./brand-partners/products/Unilever/Dove/Dove-finals.png"),
        alt: "p_dove",
      },
    },
    {
      brand: {
        src: require("./brand-partners/products/Unilever/Surf/surf-logo.png"),
        alt: "surf",
      },
      product: {
        src: require("./brand-partners/products/Unilever/Surf/Surf Final.png"),
        alt: "p_surf",
      },
    },
    {
      brand: {
        src: require("./brand-partners/products/Unilever/Domestos/domestos-logo.png"),
        alt: "domestos",
      },
      product: {
        src: require("./brand-partners/products/Unilever/Domestos/Domestos Final.png"),
        alt: "p_domestos",
      },
    },
    {
      brand: {
        src: require("./brand-partners/products/Unilever/Block and Glow/block-glow-logo.webp"),
        alt: "block_glow",
      },
      product: {
        src: require("./brand-partners/products/Unilever/Block and Glow/Block final.png"),
        alt: "p_block_glow",
        style: { transform: "scale(1.3)" },
      },
    },
    {
      brand: {
        src: require("./brand-partners/products/Unilever/Fissan/fissan-logo.png"),
        alt: "fissan",
      },
      product: {
        src: require("./brand-partners/products/Unilever/Fissan/Fissan-finals.png"),
        alt: "p_fissan",
        style: { transform: "scale(1.3)" },
      },
    },
    {
      brand: {
        src: require("./brand-partners/products/Unilever/Suave/suave-logo.png"),
        alt: "suave",
      },
      product: {
        src: require("./brand-partners/products/Unilever/Suave/Suave-final.png"),
        alt: "p_suave",
      },
    },
  ],
  torres: [
    {
      brand: {
        src: require("./brand-partners/products/Torres/Torres Selecta/torres-selecta-logo.png"),
        alt: "selecta",
      },
      product: {
        src: require("./brand-partners/products/Torres/Torres Selecta/TORRES-FINAL.png"),
        alt: "p_selecta",
        style: { transform: "scale(1.3)" },
      },
    },
    {
      brand: {
        src: require("./brand-partners/products/Torres/Torres Tapas/torres-tapas-logo.png"),
        alt: "tapas",
      },
      product: {
        src: require("./brand-partners/products/Torres/Torres Tapas/tapas-final.png"),
        alt: "p_tapas",
        style: { transform: "scale(1.2)" },
      },
    },
  ],
  reckitt: [
    {
      brand: {
        src: require("./brand-partners/products/Reckitt/detol-logo.png"),
        alt: "detol",
      },
      product: {
        src: require("./brand-partners/products/Reckitt/Detol-new.png"),
        alt: "p_detol",
      },
    },
    {
      brand: {
        src: require("./brand-partners/products/Reckitt/detol-logo.png"),
        alt: "detol",
      },
      product: {
        src: require("./brand-partners/products/Reckitt/DETTOL BODYWASH.png"),
        alt: "p_detol",
      },
    },
  ],
  permex: [
    {
      brand: {
        src: require("./brand-partners/products/Permex/kingcup-logo.png"),
        alt: "king_cup",
      },
      product: {
        src: require("./brand-partners/products/Permex/kingcup.png"),
        alt: "p_king_cup",
        style: { transform: "scale(1.4)" },
      },
    },
  ],
};

// Shop
export const ShopLists = [
  { src: require("./shop/pandarmart.png"), alt: "pandarmart" },
  { src: require("./shop/lazmart.png"), alt: "lazmart" },
  { src: require("./shop/shopee-supermarket.png"), alt: "shopee-supermarket" },
];

// Services
export const ServiceLists = [
  { icon: faLightbulb, text: "Marketing and Distribution Solutions Company" },
  { icon: faEarthAsia, text: "Top Global Brands" },
  { icon: faUsers, text: "Passionate, Young and Well-Connected Team" },
  {
    icon: faStore,
    text: "Direct to ALL Modern Trade Channels",
  },
  {
    icon: faDesktop,
    text: "Data-Driven Growth (NetSuite, The No.1 Cloud ERP)",
  },
  { icon: faTruckMoving, text: "Efficient Logistics" },
  {
    icon: faWarehouse,
    text: "State Of The Art Warehousing Facilities",
  },
  {
    icon: faCartArrowDown,
    text: "E-Commerce Presence",
  },
];

// Socials
export const Socials = [
  { icon: faFacebook, link: "https://www.facebook.com/foodpicksbybirc" },
  { icon: faInstagram, link: "https://www.instagram.com/foodpicksbybirc/" },
  {
    icon: faLinkedin,
    link: "https://www.linkedin.com/company/birc-international/",
  },
];

export const Ecommerce = [
  { brand: "pandamart", link: "https://www.foodpanda.ph/darkstore" },
  {
    brand: "lazmart",
    link: "https://pages.lazada.com.ph/wow/gcp/route/lazada/ph/upr_1000345_lazada/channel/ph/upr-router/render?hybrid=1&data_prefetch=true&prefetch_replace=1&at_iframe=1&wh_pid=/lazada/channel/ph/LazMallOne-Campaign/FkMDwfj7mn&useRMContainer=h5&showLoading=false&showTabBar=true&showSearchBar=true&useTabViewStack=true&showUserJourneyWidget=true&tab=home",
  },
  { brand: "shopee", link: "https://shopee.ph/collections/4690234" },
];
