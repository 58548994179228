import React, { useRef } from "react";
import { Grid, Box, Typography, Avatar } from "@mui/material";
import { ALT } from "../../assets";
import Form from "../../partials/AppForm";
import { TypeAnimation } from "react-type-animation";
import { Carousel } from "react-bootstrap";
import { useInView } from "framer-motion";
import { Animated } from "react-animated-css";

const styles = {
  grid: {
    color: "#23295b",
    objectFit: "contain",
  },
  carousel: {
    my: 3,
  },
  title: {
    color: "#e6b254",
    fontWeight: "bold",
    subtitle: {
      fontSize: {
        xs: "1.5rem",
        sm: "1.8rem",
        md: "2rem",
        lg: "3rem",
        xl: "4rem",
      },
    },
    label: {
      fontSize: {
        xs: "1rem",
        sm: "1rem",
        md: "1.2rem",
        lg: "1.2rem",
        xl: "2rem",
      },
    },
  },
  box: {
    m: "2rem auto",
  },
  icon: {
    width: { xs: 30, sm: 40, md: 50, lg: 50, xl: 60 },
    height: { xs: 30, sm: 40, md: 50, lg: 50, xl: 60 },
    mt: { xs: 2, sm: 2, md: 4, lg: 4, xl: 4 },
    mx: "auto",
  },

  text: {
    fontSize: {
      xs: ".8rem",
      sm: "1rem",
      md: "1rem",
      lg: "1rem",
      xl: "1.5rem",
    },
  },
};

const testimonials = [
  {
    name: "Business Development Manager, AMEA Export",
    title: "Mondelez International",
    text: "“It has been almost my great 3 years working with BIRC International and it always demonstrates the passion to be the No.1 biscuit distributor in the Philippines. Being our distributor in the Philippines, BIRC is dedicated and determined to grow our business, expand the portfolio and tap on the opportunities. BIRC reacts rapidly for any risk and opportunities which may impact the business and work closely with us for strategy development & strong execution and mitigation plan if needed.  We always have the confidence in BIRC who has the strong understanding and partnership in trade to adjust the sails and perseveres! Looking forward to more opportunities working with BIRC as our strategic partner!”",
  },
  {
    name: "VP Sales & Marketing",
    title: "PERMEX",
    text: "“Our experience with BIRC has been exceptional. We were initially drawn to partner with them due to their reputation for excellence in brand representation and marketing strategies. Throughout our partnership, BIRC consistently exceeded our expectations in terms of service quality, demonstrating excellent communication and responsiveness. Their proactive approach and dedication to achieving our brand objectives have resulted in numerous success stories, making them a valuable ally in our brand journey. We look forward to continuing our partnership with BIRC for many more successful years ahead!”",
  },
  {
    name: "Key Account Manager",
    title: "LazMart",
    text: "“Easy to work with, working with them is seamless. They are supportive with promos and they deliver all our POs on time.”",
  },
];

function Contact() {
  const ref = useRef(null);
  const ref2 = useRef(null);
  const inView = useInView(ref, { once: true });
  const inView2 = useInView(ref2,  { once: true });
  return (
    <Box>
      {/* TESTIMONIALS */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        rowSpacing={2}
        sx={{ ...styles.grid, backgroundColor: "#4288bc" }}
      >
        <Grid item xs={10}>
          <Typography
            variant="h4"
            color="initial"
            component="h4"
            ref={ref}
            sx={{
              ...styles.title,
              ...styles.title.subtitle,
              marginTop: "1rem",
            }}
          >
              {inView ? (
                <TypeAnimation
                  speed={50}
                  cursor={false}
                  sequence={[
                    'What people are saying!',
                  ]}
                />
              ): ''}
          </Typography>
        </Grid>
        <Grid item xs={10} md={6}>
          <Box
            component={Carousel}
            sx={styles.carousel}
            controls={false}
            indicators={false}
          >
            {testimonials.map((items, i) => (
              <Box component={Carousel.Item} key={i} interval={2000}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <Typography variant="p" sx={styles.text}>
                      {items.text}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Avatar sx={styles.icon} src={null} alt={ALT}>
                      {items.name[0]}
                    </Avatar>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      color="initial"
                      sx={{
                        ...styles.title,
                        ...styles.title.label,
                        fontWeight: "bold",
                      }}
                    >
                      {items.name}
                    </Typography>
                    <Typography
                      variant="p"
                      sx={{ ...styles.text, fontWeight: "bold" }}
                    >
                      {items.title}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>

      {/* CONTACTUS */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        sx={{ ...styles.grid, ...styles.box }}
      >
        <Grid item xs={12} sx={styles.griditem}>
          <Animated
            animationIn="pulse"
            isVisible={inView2}
            animationInDuration={Infinity}
          >
            <Typography
              variant="h3"
              color="initial"
              sx={{
                ...styles.title,
                ...styles.title.subtitle,
                marginTop: { xs: "1rem", md: "2rem" },
              }}
            >
              Let's do business! Contact us
            </Typography>
          </Animated>
        </Grid>
        <Grid item xs={11} md={6} sx={{ marginBottom: "1rem" }}>
          <Typography variant="p" sx={styles.text}>
            If you have any questions or business inquiries, please do not
            hesitate to send us a message. Join our network of partners. Let's
            start a conversation!
          </Typography>
        </Grid>
        <Grid item xs={12} component="div" ref={ref2}>
          <Form view="contact" />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Contact;
