import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Box, Typography, useMediaQuery } from "@mui/material";
import Form2 from "../../partials/AppForm";
import { TypeAnimation } from "react-type-animation";

const styles = {
  container: {
    objectFit: "contain",
    m: { xs: 2, sm: 2, md: 4, lg: 4, xl: 6 },
  },
  box: {
    mb: {xs: 2, sm: 2, md: 12,lg: 12, xl: 12},
  },
  title: {
    fontWeight: "bolder",
    color: "#e6b254",
  },
  text: {
    marginY: 1,
    fontSize: {
      xs: ".8rem",
      sm: "1rem",
      md: "1rem",
      lg: "1rem",
      xl: "1.3rem",
    },
  },
};

function Careers(props) {
  const { view, scrollTop } = props;
  const mobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    scrollTop();
  },[scrollTop])

  return (
    <Box sx={styles.container}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign="left"
      >
        <Grid item xs={11} md={5}>
          <Box sx={styles.box}>
            <Typography variant={mobile ? "h4" : "h3"} sx={styles.title}>
              <TypeAnimation sequence={["Join Our Growing Family!"]} cursor={false} />
            </Typography>
            <Typography variant="body2" sx={styles.text}>
              Are you passionate about making a difference? Do you thrive in
              dynamic environments where innovation and collaboration are
              encouraged? If so, we invite you to join our team!
              <br /><br />
              Send your resume today and become part of our journey towards
              success!
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Form2 view={view} />
        </Grid>
      </Grid>
    </Box>
  );
}

Careers.propTypes = {
  view: PropTypes.string,
  scrollTop: PropTypes.func,
};

Careers.defaultProps = {
  view: null,
  scrollTop: () => window.scrollTo({top: 0, behavior: "smooth"}),
};

export default Careers;
