import React from "react";
import { HashLink } from "react-router-hash-link";
import { Grid, Box, Typography, Button, IconButton } from "@mui/material";
import { LOGO, Socials } from "../assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const styles = {
  container: {
    objectFit: "contain",
    py: 2,
    mt: 6,
  },
  logo: {
    width: "30%",
    my: 3,
  },
  title: {
    my: 2,
    color: "#e6b254",
  },
  button: {
    p: 0,
    display: { md: "inline-block" },
    color: "#23295f",
    textTransform: "none",
  },
  hr: {
    mt: { xs: 6, md: 12 },
    height: ".2rem",
    backgroundColor: "black",
  },
  icon: {
    color: "black",
  },
};

const footerLinks = [
  { text: "Home", link: "/" },
  { text: "Products", link: "products" },
  { text: "Services", link: "/#services-section" },
  { text: "Careers", link: "careers" },
  { text: "About Us", link: "/#about-us-section" },
];

function Footer() {
  return (
    <Box sx={styles.container}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Grid item xs={12} md={5} textAlign={{ xs: "center", md: "left" }}>
          <Link to="/">
            <Box
              component="img"
              src={LOGO.src}
              alt={LOGO.alt}
              sx={styles.logo}
            />
          </Link>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            textAlign={{ xs: "center", md: "left" }}
          >
            <Grid item xs={12}>
              <Typography variant="h5" sx={styles.title}>
                Links
              </Typography>
            </Grid>
            {footerLinks.map((item) => (
              <Grid item xs={12} key={item.link}>
                <Button
                  component={HashLink}
                  variant="text"
                  sx={styles.button}
                  to={item.link}
                >
                  {item.text}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            rowSpacing={2}
          >
            <Grid item xs={12}>
              <Typography variant="h5" sx={styles.title}>
                Contact
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                inquiries@bircgroup.com
                <br />
                (02) 5328-2083
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                textAlign="center"
              >
                {Socials.map((item, i) => (
                  <Grid item xs={2} key={`footer-social=${i}`}>
                    <IconButton
                      sx={styles.icon}
                      size="small"
                      href={item.link}
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={item.icon} />
                    </IconButton>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={11}>
          <Box component="hr" sx={styles.hr} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            BIRC International &copy; 2024. All Rights Reserved
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;
