import React, { useRef } from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { Animated } from "react-animated-css";
import { useInView } from "framer-motion";
import { BrandPartners, TradePartners } from "../../assets";

const styles = {
  container: {
    m: 0,
  },
  box: {
    pb: 6,
    borderTop: "1px solid lightgray",
    backgroundColor: "whitesmoke",
  },
  title: {
    color: "#e6b254",
    fontWeight: "bold",
    fontSize: {
      xs: "1.5rem",
      sm: "1.8rem",
      md: "2rem",
      lg: "3rem",
      xl: "4rem",
    },
    m: 4,
  },
  logo: {
    brand: {
      width: "100%",
      objectFit: "contain",
    },
    partners: {
      width: "40%",
      height: { sm: 50, md: 120 },
      objectFit: "contain",
    },
  },
  button: {
    backgroundColor: "#23295f",
    my: { xs: 6, sm: 6, md: 10, lg: 10, xl: 10 },
    fontSize: {
      xs: ".8rem",
      sm: "1rem",
      md: "1.3rem",
      lg: "1.3rem",
      xl: "2rem",
    },
    textTransform: "none",
  },
};

function Partners() {
  const ref = useRef(null);
  const ref2 = useRef(null);
  const inView = useInView(ref, { once: true });
  const inView2 = useInView(ref2, { once: true });
  return (
    <Box sx={styles.container}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        component="div"
        ref={ref}
        rowSpacing={{ xs: 2, xl: 3 }}
      >
        <Grid item xs={12}>
          <Typography variant="h4" sx={styles.title}>
            Our Business Partners
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            columnSpacing={4}
          >
            {BrandPartners.map((brand, i) => (
              <Grid item xs={2} key={`${brand.alt}-${i}`}>
                <Animated
                  animationIn="fadeIn"
                  animationInDuration={2000}
                  isVisible={inView}
                >
                  <Box
                    component="img"
                    src={brand.src}
                    alt={brand.alt}
                    sx={{ ...styles.logo.brand, ...brand.style }}
                  />
                </Animated>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            component={Link}
            variant="contained"
            sx={styles.button}
            to="products"
            // ref={ref}
          >
            View Products
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            sx={styles.box}
            component="div"
            ref={ref2}
          >
            <Grid item xs={12} textAlign="left">
              <Typography variant="h4" sx={styles.title}>
                Our Trade Channels
              </Typography>
            </Grid>
            {TradePartners.map((item) => (
              <Grid item xs={3} key={item.alt}>
                <Animated
                  animationIn="fadeInUp"
                  animationInDuration={1000}
                  isVisible={inView2}
                >
                  <Box
                    component="img"
                    src={item.src}
                    alt={item.alt}
                    sx={styles.logo.partners}
                  />
                </Animated>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Partners;
