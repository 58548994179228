import React, { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const styles = {
  box: {
    border: "2px dashed #ccc",
    borderRadius: "5px",
    p: 2,
    textAlign: "center",
    cursor: "pointer",
    overflow: "hidden",
  },
  label: {
    cursor: "pointer",
  },
  input: { 
    display: "none",
  },
};

const FileInputForm = ({ onFileChange, errors }) => {
  const FILE_SIZE = 5 * 1024 * 1024;
  const [error, setError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event?.target?.files[0];
    setSelectedFile(file?.name);
    handleFile(file);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event?.dataTransfer?.files[0];
    setSelectedFile(file?.name);
    handleFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleFile = (file) => {
    if (file?.size > FILE_SIZE) {
      setError("File size exceeds the limit of 5MB.");
    } else {
      setError(null);
      onFileChange(file);
    }
  };

  useEffect(() => {
    if (errors) {
      setError(errors);
    }
  }, [errors]);

  return (
    <>
      <Box sx={styles.box} onDrop={handleDrop} onDragOver={handleDragOver}>
        <Box
          component="label"
          htmlFor="fileInput"
          display="block"
          sx={styles.label}
        >
          <CloudUploadIcon />
          <Typography>
            {selectedFile ? selectedFile : "Click or drag files here to upload"}
          </Typography>
          <Box
            component="input"
            accept="image/*,.docx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
            style={styles.input}
            multiple
            type="file"
            onChange={handleFileChange}
            id="fileInput"
          />
        </Box>
        {!selectedFile && (
          <Typography variant="body2" color="textSecondary">
            (File type: PDF and DOCX, Max file size: 5MB)
          </Typography>
        )}
      </Box>
      {error && (
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      )}
    </>
  );
};

export default FileInputForm;
