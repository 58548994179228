import React from "react";
import { Grid, Box, Card, Typography, CardMedia } from "@mui/material";
import { Carousel } from "react-bootstrap";
import { MDMain, MDAssets, MDVideo } from "../../assets";
import AppParallax from "../../component/AppParallax";

const styles = {
  box: {
    mt: 7,
  },
  carousel: {
    my: 2,
    py: { xs: 5, md: 10, xl: 20 },
    backgroundColor: "black",
  },
  card: {
    maxWidth: { xs: "100%", md: "50%" },
    mx: "auto",
    my: 3,
  },
  cardMedia: {
    height: { xs: 200, md: 350, xl: 500 },
  },
  title: {
    mx: { xs: 2, sm: 4 },
    mt: { xs: 3, md: 6 },
    fontWeight: "bold",
    fontSize: {
      xs: "1.5rem",
      sm: "1.8rem",
      md: "2rem",
      lg: "3rem",
      xl: "4rem",
    },
    color: "#e6b254",
  },
  header: {
    fontWeight: "bold",
    mx: { xs: 2, sm: 4 },
    fontSize: {
      xs: "1rem",
      sm: "1rem",
      md: "1.5rem",
      lg: "1.5rem",
      xl: "2rem",
    },
  },
  text: {
    mx: { xs: 2, sm: 4 },
    fontSize: {
      xs: ".8rem",
      sm: ".8rem",
      md: "1rem",
      lg: "1rem",
      xl: "2rem",
    },
    color: "#23295b",
  },
  img: {
    width: "100%",
    height: { xs: "5rem", sm: "10rem", md: "12rem", lg: "16rem", xl: "30rem" },
  },
};

function MarketingDistribution() {
  return (
    <Box sx={styles.box}>
      <Grid container rowSpacing={{xs: 1, xl: 3}}>
        <Grid item xs={12} md={7}>
          <Typography variant="h3" sx={styles.title}>
            Marketing and Distribution Solutions Company
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" sx={styles.header}>
            We build brands
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="body2" sx={styles.text}>
            Our team breathes life into your brand story. We develop targeted
            marketing campaigns that resonate with your audience, leveraging
            cutting-edge digital strategies and traditional channels.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Card sx={styles.card}>
            <CardMedia
              component="video"
              controls
              src={MDVideo.src}
              title="BIRC promo video"
              sx={styles.cardMedia}
            />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Box component={Carousel} sx={styles.carousel}>
            {MDAssets.map((_, i) => (
              <Box component={Carousel.Item} key={i} interval={2000}>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={9}>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      columnSpacing={3}
                    >
                      {MDAssets[i].map((item, j) => (
                        <Grid item xs={3} key={j}>
                          <Box
                            component="img"
                            src={item.src}
                            alt={item.alt}
                            sx={styles.img}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Typography variant="h3" sx={styles.title}>
            Passionate, Young and Well-Connected Team
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="body2" sx={styles.text}>
            A team of experienced professionals working with Multinational
            FMCG's. We leverage our passion and industry connections to develop
            successful results.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <AppParallax
            element={
              <Box
                component="img"
                src={MDMain.src}
                alt={MDMain.alt}
                width="100%"
              />
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default MarketingDistribution;
