import { gql } from "@apollo/client";

const MUTATION_SENDAPPLICATION = gql`
  mutation SendApplicationEmail($input: sendApplicationEmailInput!) {
    sendApplicationEmail(input: $input) {
      id
      type
      firstName
      lastName
      designation
      company
      contactNumber
      emailAddress
      message
      attachment
      createdAt
    }
  }
`;

export default MUTATION_SENDAPPLICATION;