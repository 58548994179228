import React, { useState } from "react";
import {
  AppBar,
  Button,
  Box,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { LOGO, Socials } from "../assets";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { HashLink } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const theme = createTheme({
  palette: {
    text: {
      primary: "#000000",
    },
  },
});

const styles = {
  appbar: {
    objectFit: "contain",
    background: "transparent",
    boxShadow: "none",
    color: "black",
  },
  box: {
    flexGrow: 3,
    mobile: {
      display: { xs: "flex", sm: "flex", md: "none", lg: "none", xl: "none" },
    },
    web: {
      display: { xs: "none", sm: "none", md: "flex", lg: "flex", xl: "flex" },
    },
  },
  logo: {
    mr: 1,
    my: 2,
    width: { xs: "5rem", sm: "5rem", md: "10rem", lg: "10rem", xl: "15rem" },
    height: {
      xs: "1.8rem",
      sm: "1.8rem",
      md: "3.5rem",
      lg: "3.5rem",
      xl: "5rem",
    },
    mobile: {
      display: { xs: "flex", md: "none" },
    },
    web: {
      display: { xs: "none", md: "flex" },
    },
  },
  button: {
    mt: {
      md: "1.5rem",
      lg: "1.5rem",
      xl: "2rem",
    },
    width: { xs: 0, sm: 0, md: "5rem", lg: "6rem", xl: "8rem" },
    height: { xs: 0, sm: 1, md: "2rem", lg: "3rem", xl: "3rem" },
    fontSize: {
      xs: ".8rem",
      sm: ".8rem",
      md: ".8rem",
      lg: "1rem",
      xl: "1.5rem",
    },
    textAlign: "center",
    fontWeight: "normal",
    color: "#23295f",
    display: "block",
    textTransform: "none",
  },
  socials: {
    mx: 1,
    fontSize: {
      xs: "1rem",
      sm: "1rem",
      md: "1.5rem",
      lg: "1.5rem",
      xl: "2.5rem",
    },
    color: "black",
  },
};

function Navbar() {
  const [anchorElNav, setAnchorElNav] = useState(null);

  const pages = [
    { view: "Home", link: "/" },
    { view: "Products", link: "products" },
    { view: "Services", link: "/#services-section" },
    { view: "Careers", link: "careers" },
    { view: "About Us", link: "/#about-us-section" },
  ];

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" sx={styles.appbar}>
        <Toolbar>
          <Box sx={{ ...styles.box, ...styles.box.mobile }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={(event) => setAnchorElNav(event.currentTarget)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Link to="/">
              <Box
                component="img"
                src={LOGO.src}
                alt={LOGO.alt}
                sx={{ ...styles.logo, ...styles.logo.mobile }}
              />
            </Link>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              open={Boolean(anchorElNav)}
              onClose={() => setAnchorElNav(null)}
            >
              {pages.map((page) => (
                <MenuItem key={page.link} onClick={() => setAnchorElNav(null)}>
                  <Button
                    key={page.link}
                    component={HashLink}
                    to={page.link}
                    sx={styles.button}
                  >
                    {page.view}
                  </Button>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ ...styles.box, ...styles.box.web }}>
            <Link to="/">
              <Box
                component="img"
                src={LOGO.src}
                alt={LOGO.alt}
                sx={{ ...styles.logo, ...styles.logo.web }}
              />
            </Link>
            {pages.map((page) => (
              <Button
                key={page.link}
                component={HashLink}
                to={page.link}
                sx={styles.button}
              >
                {page.view}
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {Socials.map((item, i) => (
              <IconButton
                key={i}
                sx={styles.socials}
                href={item.link}
                target="_blank"
              >
                <FontAwesomeIcon icon={item.icon} />
              </IconButton>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default Navbar;
