import React, { useRef } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { Animated } from "react-animated-css";
import { useInView } from "framer-motion";

const styles = {
  container: {
    m: 3,
    p: 2,
    color: "#23295f",
  },
  title: {
    fontWeight: "bold",
    fontSize: {
      xs: "1rem",
      sm: "1.5rem",
      md: "1.8rem",
      lg: "2rem",
      xl: "3rem",
    },
    color: "#e6b254",
    marginBottom: 2,
  },
  box: {
    m: { xs: 2, sm: 2, md: 3, lg: 3, xl: 4 },
  },
  section: {
    height: { md: 280, lg: 220, xl: 400 },
  },
  text: {
    fontSize: {
      xs: ".8rem",
      sm: "1rem",
      md: "1.2rem",
      lg: "1.2rem",
      xl: "2rem",
    },
  },
};

const aboutText = [
  {
    title: "About Us",
    text: "<b>BIRC</b> started out in 2006 as an importer/ wholesaler of snacking products from USA. It has become a reputable distributor trusted by top-tier Fortune 500 companies.",
  },
  {
    title: "Vision",
    text: " We aim to be the best distributor in the Philippines trusted both by our suppliers and our customers. Our goal is to become the go- to distributor for the top FMCG companies in the world.",
  },
  {
    title: "Mission",
    text: "Represent the world's best FMCG brands to the best of our abilities. Our trade execution will be second to none. Make products available everyday, merchandised well - the right product, at the right price, at the right channel merchandised properly.",
  },
];

function About() {
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });

  return (
    <Box sx={styles.container} component="div" id="about-us-section">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className="homepage"
        component="div"
        ref={ref}
      >
        {aboutText.map((item, i) => (
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={i}>
            <Box sx={styles.box}>
              <Typography variant="h5" sx={styles.title}>
                {item.title}
              </Typography>
              <Animated
                animationIn="fadeIn"
                animationInDuration={2000}
                isVisible={inView}
              >
                <Box sx={styles.section}>
                  <Typography
                    variant="p"
                    sx={styles.text}
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  />
                </Box>
              </Animated>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default About;
