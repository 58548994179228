import React, { useEffect } from "react";
import PropTypes from "prop-types";

// // v1
// import Header from "./views/v1/Header";
// import About from "./views/v1/About";
// import Partners from "./views/v1/Partners";
// import Contact from "./views/v1/Contact";
// import Services from "./views/v1/Services";

// v2
import Header from "./views/v2/Header";
import About from "./views/v2/About";
import Services from "./views/v2/Services";
import Partners from "./views/v2/Partners";
import Shop from "./views/v2/Shop";
import Contact from "./views/v2/Contact"
import Apply from "./views/v2/Apply";
import MarketingDistribution from "./views/v2/MarketingDistribution";
import { Box } from "@mui/material";

function App(props) {
  const { scrollTop } = props;
  
  useEffect(() => {
    scrollTop();
  },[scrollTop])

  return (
    <Box component="div" id="home_section">
      <Header />
      <About />
      <Services />
      <MarketingDistribution />
      <Shop />
      <Partners />
      <Contact view="contact" />
      <Apply />
    </Box>
  );
}

App.propTypes = {
  scrollTop: PropTypes.func,
};

App.defaultProps = {
  scrollTop: () => window.scrollTo({top: 0, behavior: "smooth"}),
};

export default App;
