import React from "react";
import { Routes, Route } from "react-router-dom";
import App from "./App";
import NoPage from "./views/v1/NoPage";
import Products2 from "./views/v2/Products";
import ProductLists from "./partials/ProductLists";
import Form2 from "./partials/AppForm";
import About2 from "./views/v2/About";
import Services2 from "./views/v2/Services";
import Careers from "./views/v2/Careers";

export function routes() {
  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="*" element={<NoPage />} />
      <Route path="about-us" element={<About2 />} />
      <Route path="careers" element={<Careers view="careers" />} />
      <Route path="services" element={<Services2 />} />
      <Route path="products" element={<Products2 />} />
      <Route path="products/product-lists" element={<ProductLists />} />
      <Route path="contact-us" element={<Form2 contact />} />
      <Route path="apply-now" element={<Form2 view="apply" />} />
    </Routes>
  );
}
