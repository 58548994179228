import React, { useRef } from "react";
import {
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Chip,
  Button,
} from "@mui/material";
import { ShopLists, Ecommerce } from "../../assets";
import { motion, useInView } from "framer-motion";
import { Animated } from "react-animated-css";

const styles = {
  container: {
    backgroundColor: "#23295f",
    py: { xs: 3, md: 8 },
  },
  card: {
    width: "100%",
    height: { xl: "30rem" },
  },
  title: {
    color: "#e6b254",
    fontWeight: "bold",
    fontSize: {
      xs: "1.5rem",
      sm: "1.8rem",
      md: "2rem",
      lg: "3rem",
      xl: "4rem",
    },
  },
  subtitle: {
    color: "#e6b254",
    fontWeight: "bold",
    fontSize: {
      xs: "1rem",
      sm: "1.2rem",
      md: "1.2rem",
      lg: "1.3rem",
      xl: "1.3rem",
    },
  },
  text: {
    color: "white",
    fontSize: {
      xs: ".8rem",
      sm: "1rem",
      md: "1rem",
      lg: "1.3rem",
      xl: "1.5rem",
    },
  },
  img: {
    py: { xs: 3, sm: 3, md: 6, lg: 6, xl: 6 },
    width: { xs: "60%", md: "80%" },
    height: { xs: "5rem", md: "9rem", xl: "12rem" },
    objectFit: "contain",
  },
  button: {
    width: { xs: "100%", sm: "100%", md: "80%", xl: "60%" },
    mt: { xl: 15 },
    py: { xl: 5 },
    color: "white",
    textTransform: "none",
    overflow: "auto",
    fontSize: {
      xs: ".4rem",
      sm: ".6rem",
      md: "1rem",
      lg: "1rem",
      xl: "2.5rem",
    },
    backgroundColor: "#23295f",
    "&:hover": {
      backgroundColor: "#1565c0",
    },
  },
};

function Shop() {
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });
  return (
    <Box>
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        textAlign="center"
        rowSpacing={{ xs: 1, md: 2 }}
        sx={styles.container}
        component="div"
        ref={ref}
      >
        <Grid item xs={12}>
          <Typography
            variant="h4"
            sx={styles.title}
            component={Animated}
            animationIn="pulse"
            animationInDuration={Infinity}
            isVisible={inView}
          >
            E-Commerce Presence
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" sx={styles.subtitle}>
            Enhanced Brand Visibility: We are E-Commerce ready!
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2" sx={styles.text}>
            We're excited to bring you the ease and convenience of online
            shopping! Now you can browse our full selection of our brands 24/7
            and have them delivered straight to you door.
          </Typography>
        </Grid>
        <Grid item xs={10} sm={8} md={6} lg={6} xl={8}>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            textAlign="center"
            columnSpacing={{ xs: 1, sm: 2, md: 4 }}
            mt={5}
          >
            {ShopLists.map((item, i) => (
              <Grid item xs={4} key={`${item.alt}-${i}`}>
                <Card
                  sx={styles.card}
                  component={motion.div}
                  whileHover={{ scale: 1.2 }}
                  transition={{ type: "spring", stiffness: 200, damping: 10 }}
                >
                  <CardActionArea>
                    <CardMedia>
                      <Box
                        component="img"
                        textAlign="center"
                        src={item.src}
                        alt={item.alt}
                        sx={styles.img}
                      />
                    </CardMedia>
                    <CardContent>
                      <Chip
                        clickable
                        component="a"
                        label="Shop Now"
                        variant="contained"
                        sx={styles.button}
                        href={Ecommerce[i].link}
                      />
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Shop;
