import React, { useRef } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const styles = {
  grid: {
    backgroundColor: "#e6b254",
    py: 10,
    my: { xs: 2, sm: 2, md: 4, lg: 4, xl: 6 },
  },
  header: {
    fontWeight: "bold",
    fontSize: { xs: "2rem", sm: "3rem", md: "4rem", lg: "4rem", xl: "5rem" },
    color: "#23295b",
  },
  text: {
    m: 1,
    fontSize: {
      xs: ".8rem",
      sm: "1rem",
      md: "1rem",
      lg: "1.3rem",
      xl: "1.5rem",
    },
  },
  button: {
    fontWeight: "bold",
    backgroundColor: "white",
    color: "black",
    "&:hover": {
      backgroundColor: "whitesmoke",
    },
    fontSize: {
      xs: ".8rem",
      sm: "1rem",
      md: "1rem",
      lg: "1rem",
      xl: "1.3rem",
    },
    textTransform: "none",
  },
};

function Apply() {
  const ref = useRef(null);
  return (
    <>
      <Grid
        container
        sx={styles.grid}
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Grid item xs={12} sx={styles.text}>
          <Typography
            variant="h3"
            sx={styles.header}
            component={motion.div}
            whileInView={{ scale: 1.05 }}
            transition={{
              duration: 1.3,
              repeat: Infinity,
              repeatType: "reverse",
            }}
          >
            Join our growing family!
          </Typography>
        </Grid>
        <Grid item xs={12} sx={styles.text}>
          <Typography variant="p">
            For employment opportunities in BIRC International, please click the
            link below.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={styles.text}>
          <Button
            component={Link}
            to="careers"
            variant="contained"
            sx={styles.button}
            ref={ref}
          >
            Apply Now
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default Apply;
