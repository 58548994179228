import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Grid, Box, Typography, Button, Card } from "@mui/material";
import { HeaderImg, BrandPartners, BrandProducts } from "../../assets";
import { Animated } from "react-animated-css";
import { useInView } from "framer-motion";

const styles = {
  grid: {
    width: "100%",
    objectFit: "contain",
    overflow: "hidden",
  },
  box: {
    py: { xs: 5, md: 10 },
  },
  card: {
    height: { xs: 140, sm: 250, md: 340, lg: 350, xl: 480 },
  },
  button: {
    fontSize: {
      xs: ".8rem",
      sm: "1rem",
      md: "1rem",
      lg: "1rem",
      xl: "1.3rem",
    },
    backgroundColor: "#23295b",
    textTransform: "none",
    py: 1,
  },
  backround: {
    width: "100%",
    objectFit: "cover",
  },
  title: {
    color: "#23295b",
    fontWeight: "bold",
    fontSize: {
      xs: "2rem",
      sm: "3rem",
      md: "4.5rem",
      lg: "4.5rem",
      xl: "7rem",
    },
  },
  text: {
    fontSize: {
      xs: "1rem",
      sm: "1rem",
      md: "1.2rem",
      lg: "1.2rem",
      xl: "2rem",
    },
  },
  brand: {
    mb: { xs: 5, md: 10 },
    objectFit: "contain",
  },
  products: {
    my: { xs: 1, sm: 2, md: 3 },
    width: "70%",
    height: { xs: "3rem", sm: "6rem", md: "8rem", lg: "8rem", xl: "10rem" },
    objectFit: "contain",
  },
  img: {
    width: "100%",
    height: { xs: "3rem", sm: "6rem", md: "8rem", lg: "8rem", xl: "12rem" },
    objectFit: "contain",
  },
};

const productList = [
  {
    id: "mondelez_product_section",
    logo: { ...BrandPartners[0], size: "25%" },
    products: BrandProducts.mondelez,
    containerOptions: {
      bgColor: "#e6b254",
      columnContainer: { xs: 10 },
      columnItems: { xs: 4, md: 3 },
    },
  },
  {
    id: "unilever_product_section",
    logo: { ...BrandPartners[1], size: "10%" },
    products: BrandProducts.unilever,
    containerOptions: {
      bgColor: "white",
      columnContainer: { xs: 8 },
      columnItems: { xs: 6, md: 4 },
    },
  },
  {
    id: "torres_product_section",
    logo: { ...BrandPartners[2], size: "10%" },
    products: BrandProducts.torres,
    containerOptions: {
      bgColor: "#438abf",
      columnContainer: { xs: 8, md: 10 },
      columnItems: { xs: 6, md: 3 },
    },
  },
  {
    id: "reckitt_product_section",
    logo: { ...BrandPartners[3], size: "10%" },
    products: BrandProducts.reckitt,
    containerOptions: {
      bgColor: "#23295f",
      columnContainer: { xs: 8, md: 10 },
      columnItems: { xs: 6, md: 3 },
    },
  },
  {
    id: "permex_product_section",
    logo: { ...BrandPartners[4], size: "10%" },
    products: BrandProducts.permex,
    containerOptions: {
      bgColor: "white",
      columnContainer: { xs: 8, md: 10 },
      columnItems: { xs: 6, md: 3 },
    },
  },
];

function Products(props) {
  const { scrollTop } = props;
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });
  const ref2 = useRef(null);
  const inView2 = useInView(ref2, { once: true });
  const ref3 = useRef(null);
  const inView3 = useInView(ref3, { once: true });
  const ref4 = useRef(null);
  const inView4 = useInView(ref4, { once: true });
  const ref5 = useRef(null);
  const inView5 = useInView(ref5, { once: true });
  const ref6 = useRef(null);
  const inView6 = useInView(ref6, { once: true });

  useEffect(() => {
    scrollTop();
  }, [scrollTop]);

  return (
    <Box component="div" id="products-section">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        sx={styles.grid}
        component="div"
        ref={ref}
        rowSpacing={2}
      >
        <Grid item xs={4} md={4} lg={3}>
          <Animated
            animationIn="fadeInUp"
            animationInDuration={1000}
            isVisible={inView}
          >
            <Typography variant="h2" sx={styles.title}>
              Our Business Partners
            </Typography>
          </Animated>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="p" sx={styles.text}>
            Bringing the Best of the World to the Philippines
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            href="#mondelez_product_section"
            sx={styles.button}
          >
            View Products
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Animated animationIn="fadeIn" animationInDuration={2000}>
            <Box
              component="img"
              src={HeaderImg.products.src}
              alt={HeaderImg.products.alt}
              style={styles.backround}
            />
          </Animated>
        </Grid>
      </Grid>

      {/* PRODUCTS */}

      {productList.map((items, index) => (
        <Box key={items.id}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            backgroundColor={items.containerOptions.bgColor}
            sx={styles.box}
            component="div"
            id={items.id}
            ref={
              index === 0
                ? ref2
                : index === 1
                ? ref3
                : index === 2
                ? ref4
                : index === 3
                ? ref5
                : ref6
            }
          >
            <Grid item xs={12}>
              <Box
                component="img"
                src={items.logo.src}
                alt={items.logo.alt}
                sx={styles.brand}
                width={items.logo.size}
              />
            </Grid>
            <Grid
              container
              {...items.containerOptions.columnContainer}
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              spacing={{ xs: 1, md: 3 }}
            >
              {items.products.map((product, i) => (
                <Grid
                  item
                  {...items.containerOptions.columnItems}
                  key={`${product.alt}-${i}`}
                >
                  <Animated
                    animationIn="fadeInUp"
                    animationInDuration={1000}
                    isVisible={
                      index === 0
                        ? inView2
                        : index === 1
                        ? inView3
                        : index === 2
                        ? inView4
                        : index === 3
                        ? inView5
                        : inView6
                    }
                  >
                    <Card sx={styles.card}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                        spacing={{ xs: 1, md: 2 }}
                      >
                        <Grid item xs={12}>
                          <Box
                            component="img"
                            src={product.brand.src}
                            alt={product.brand.alt}
                            sx={styles.products}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                            component="img"
                            src={product.product.src}
                            alt={product.product.alt}
                            sx={{ ...styles.img, ...product.product.style }}
                          />
                        </Grid>
                      </Grid>
                    </Card>
                  </Animated>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
}

Products.propTypes = {
  scrollTop: PropTypes.func,
};

Products.defaultProps = {
  scrollTop: () => window.scrollTo({ top: 0, behavior: "smooth" }),
};

export default Products;
