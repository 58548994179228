import React, { useEffect, useRef } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HeaderImg, ServiceLists } from "../../assets";
import { Animated } from "react-animated-css";
import { useInView } from "framer-motion";
import { isMobile } from "react-device-detect";
import ExpandingCircle from "../../component/ExpandingCircle";
import AppParallax from "../../component/AppParallax";

const styles = {
  container: {
    display: "grid",
    position: "relative",
    overflow: "hidden",
    backgroundColor: "whitesmoke",
    pt: 50,
  },
  grid: {
    zIndex: 100,
  },
  box: {
    mx: { xs: 5, md: 10 },
    mb: { xs: 5, md: 10 },
  },
  section: {
    height: isMobile ? "9rem" : "6rem",
  },
  title: {
    fontWeight: "bold",
    fontSize: {
      xs: "1.5rem",
      sm: "1.8rem",
      md: "2rem",
      lg: "2.5rem",
      xl: "3.5rem",
    },
    color: "#e6b254",
  },
  icon: {
    fontSize: { xs: "1rem", sm: "1rem", md: "2rem" },
  },
  text: {
    mt: 2,
    fontWeight: "bold",
    fontSize: {
      xs: ".8rem",
      sm: "1rem",
      md: "1.1rem",
      lg: "1.1rem",
      xl: "2rem",
    },
    color: "white",
  },
};

function Services() {
  const ref = useRef(null);
  const inView = useInView(ref);

  useEffect(() => {
    if (ref) {
      ref.current.scrollIntoView({ block: "start" });
    }
  }, []);

  return (
    <Box sx={styles.container} component="div">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        sx={styles.grid}
        component="div"
        ref={ref}
        id="services-section"
        spacing={2}
      >
        <ExpandingCircle />
        <Grid item xs={12}>
          <Typography
            variant="h4"
            sx={{ ...styles.title, mb: { xs: 1, xl: 4 } }}
          >
            Services
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <AppParallax
            element={
              <Box
                component="img"
                src={HeaderImg.services.src}
                alt={HeaderImg.services.alt}
                width="100%"
                height="30vh"
                zIndex={1}
              />
            }
            styles={{ zIndex: -1 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            sx={{ ...styles.title, mb: { xs: 3, xl: 5 } }}
          >
            What We Offer
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          component="div"
          spacing={{ xs: 2, xl: 12 }}
          sx={styles.box}
          ref={ref}
        >
          {ServiceLists.map((items) => (
            <Grid item xs={4} key={items.text}>
              <Box
                component={Animated}
                animationIn="fadeInUp"
                animationInDuration={1000}
                isVisible={inView}
                sx={styles.section}
              >
                <Box
                  component={FontAwesomeIcon}
                  icon={items.icon}
                  color="#e6b254"
                  sx={styles.icon}
                />
                <Typography variant="body2" sx={styles.text}>
                  {items.text}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Services;
