import Joi from 'joi';

const applySchema = Joi.object({
  firstName: Joi.string().required().regex(/^[a-zA-Z ]*$/).label('First Name').messages({
    'string.pattern.base': '{{#label}} must only contain alphabet characters',
  }),
  lastName: Joi.string().required().regex(/^[a-zA-Z ]*$/).label('Last Name').messages({
    'string.pattern.base': '{{#label}} must only contain alphabet characters',
  }),
  emailAddress: Joi.string().email({tlds: false}).required().label('Email'),
  message: Joi.string().min(3).max(255).optional().label('Message'),
  file: Joi.any().required().label('Attachment'),
});

export default applySchema;