import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { useParallax } from "react-scroll-parallax";
import { useInView } from "react-intersection-observer";

const styles = {
  circle: {
    zIndex: -100,
    position: "absolute",
    borderRadius: "50%",
    backgroundColor: "#23295b",
    top: "15%",
  },
  ref: {
    position: "absolute",
    top: "40%",
    height: "100rem",
  },
};

function ExpandingCircle() {
  const { ref, inView } = useInView({ threshold: 1 });
  const [circleDimension, setCircleDimension] = useState({
    width: 100,
    height: 100,
  });
  const [circleAlignment, setCircleAlignment] = useState(null);
  const circle = useParallax(
    inView && {
      translate: [1, 1],
      scale: [1, 20, "easeInExpo"],
      onProgressChange: () => setCircleAlignment(null),
    }
  );

  useEffect(() => {
    if (inView) {
      setCircleDimension({
        width: { xs: 150, xl: 200 },
        height: { xs: 150, xl: 200 },
      });
    }
    return () => {
      setCircleDimension({ width: 100, height: 100 });
      setCircleAlignment({ left: "50%", transform: "translate(-50%, -50%)" });
    };
  }, [inView]);

  return (
    <>
      <Box
        ref={circle.ref}
        sx={{
          ...circleDimension,
          ...circleAlignment,
          ...styles.circle,
        }}
      />
      <Box sx={styles.ref} ref={ref} />
    </>
  );
}

export default ExpandingCircle;
