import React, { useState } from "react";
import CookieConsent from "react-cookie-consent";
import { Grid, Box } from "@mui/material";

const styles = {
  box: {
    background: "#212121",
    color: "white",
  },
  button: {
    background: "#e6b254",
    color: "white",
    fontSize: "1rem",
  },
};

function CookiePopUp() {
  const [showCookiePopup, setShowCookiePopup] = useState(true);

  const handleAccept = () => {
    setShowCookiePopup(false);
  };

  return (
    <Grid className="site-cookie" container textAlign="center">
      {showCookiePopup && (
        <Box
          component={CookieConsent}
          location="bottom"
          buttonText="Got it"
          cookieName="birc-cookie"
          sx={styles.box}
          buttonStyle={styles.button}
          expires={365}
          onAccept={handleAccept}
        >
          This website uses cookies to ensure you get the best experience on our
          website.
        </Box>
      )}
    </Grid>
  );
}

export default CookiePopUp;
