import Joi from 'joi';

const contactSchema = Joi.object({
  firstName: Joi.string().required().regex(/^[a-zA-Z ]*$/).label('First Name').messages({
    'string.pattern.base': '{{#label}} must only contain alphabet characters',
  }),
  lastName: Joi.string().required().regex(/^[a-zA-Z ]*$/).label('Last Name').messages({
    'string.pattern.base': '{{#label}} must only contain alphabet characters',
  }),
  designation: Joi.string().required().label('Designation'),
  company: Joi.string().required().label('Company'),
  contactNumber: Joi.string().min(11).max(13).required().label('Contact Number'),
  emailAddress: Joi.string().email({tlds: false}).required().label('Email'),
  message: Joi.string().min(3).max(255).label('Message'),
});

export default contactSchema;