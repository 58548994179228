import _ from 'lodash';

export default function Validate(input, schema) {
  const result = {validData: {}, errors: {}, valid: false};
  const validate = schema.validate(input, {abortEarly: false});
  (validate?.error?.details || []).map(error => {
    result.errors[error.context.key] = error.message;
    return  result.valid = false;
  });

  if (_.isEmpty(validate.error)) {
    result.validData = validate.value;
    result.valid = true;
  }
  return result;
};