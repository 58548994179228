import React from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import logo_unilver from "../assets/brand-partners/unilever-logo.png";
import logo_torres from "../assets/brand-partners/torres-logo.png";
import prod1 from "../assets/brand-partners/products/prod1.png";
import prod2 from "../assets/brand-partners/products/prod2.png";

const styles = {
  grid: {
    textAlign: "center",
  },
  title: {
    margin: "1rem",
    textAlign: "left",
  },
  logo: {
    width: {xs: "3rem", md: "5rem"},
  },
  img: {
    width: { xs: "4rem", md: "8rem" },
  },
  text: {
    color: "#e6b254",
    fontSize: { xs: ".8rem", md: "1rem" },
  },
  button: {
    backgroundColor: "#23295b",
    textTransform: "none",
  },
};

function ProductLists() {
  const products = [
    {
      brand: logo_torres,
      items: [
        { img: prod1, text: "TORRES IBERIAN HAM" },
        { img: prod1, text: "TORRES IBERIAN HAM" },
        { img: prod1, text: "TORRES IBERIAN HAM" },
        { img: prod1, text: "TORRES IBERIAN HAM" },
      ],
      id: "key1",
    },
    {
      brand: logo_unilver,
      items: [
        { img: prod2, text: "DOVE BODY WASH SENSITIVE SKIN" },
        { img: prod2, text: "DOVE BODY WASH SENSITIVE SKIN" },
        { img: prod2, text: "DOVE BODY WASH SENSITIVE SKIN" },
        { img: prod2, text: "DOVE BODY WASH SENSITIVE SKIN" },
      ],
      id: "key2",
    },
  ];
  return (
    <Grid container sx={styles.grid} spacing={4}>
      {products.map((product) => (
        <React.Fragment key={product.id}>
          <Grid item xs={12} sx={styles.title}>
            <Box
              component="img"
              src={product.brand}
              alt={`brand-${product.id}`}
              sx={styles.logo}
            />
          </Grid>
          {product.items.map((item, index) => (
            <Grid item xs={6} sm={6} md={3} xl={3} key={index}>
              <Box
                component="img"
                src={item.img}
                alt={`item-${index}`}
                sx={styles.img}
              />
              <br />
              <Typography variant="h6" sx={styles.text}>
                {item.text}
              </Typography>
            </Grid>
          ))}
          <Grid item xs={12} key={`button-${product.id}`}>
            <Button variant="contained" sx={styles.button}>
              See all
            </Button>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
}

export default ProductLists;
