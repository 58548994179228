import React from "react";
import { Box } from "@mui/material";
import { ParallaxBanner } from "react-scroll-parallax";

function AppParallax({ element, styles }) {
  return (
    <Box
      component={ParallaxBanner}
      layers={[
        {
          children: element,
          expanded: true,
          speed: -30,
          scale: [1, 1.3, "easeIn"],
          translateY: [1, 1, "easeInOut"],
          style: { position: "relative", inset: 0 },
        },
      ]}
      style={styles}
    />
  );
}

export default AppParallax;
